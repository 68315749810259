import { Button, Flex, Text, VStack } from "@chakra-ui/react";
import { useRouter } from "next/router";
import { EAnimation } from "../@types/global_types";
import LottiePlayer from "../components/LottiePlayer";
import MetaTags from "../components/MetaTags";
const NotFoundPage = () => {
  const router = useRouter();
  return <Flex justifyContent="center" alignItems="center" width="100vw" height="90vh" data-sentry-element="Flex" data-sentry-component="NotFoundPage" data-sentry-source-file="404.tsx">
            <MetaTags title="Page not found" data-sentry-element="MetaTags" data-sentry-source-file="404.tsx" />
            <VStack spacing={8} data-sentry-element="VStack" data-sentry-source-file="404.tsx">
                <LottiePlayer animation={EAnimation.notFound404} loop={false} autoplay style={{
        width: "550px",
        height: "550px"
      }} data-sentry-element="LottiePlayer" data-sentry-source-file="404.tsx" />
                <Text fontSize="30px" fontWeight="bold" data-sentry-element="Text" data-sentry-source-file="404.tsx">
                    Page not found
                </Text>
                <Button variant="primary" width="300px" size="lg" onClick={() => router.push("/")} data-sentry-element="Button" data-sentry-source-file="404.tsx">
                    Back to Home
                </Button>
            </VStack>
        </Flex>;
};
export default NotFoundPage;